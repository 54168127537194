<template>
  <div id="dashboard">

    <div class="row no-gutters">
      <app-title title="mdl.conferma-t.title"></app-title>
      <div class="col-10 offset-1">
        <app-breadcrumb items="mdl.bread_home;/mdl "> </app-breadcrumb>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-10 offset-1 col-md-6 offset-md-3 mt-5">
        <div class="o-registration-collapse__success" v-if="success">
          <p><strong>{{$t('mdl.conferma-t.success-heading')}}</strong></p><br>
          <p>{{$t('mdl.conferma-t.success-text')}}</p>
          <div class="col-12">
            <b-button class="col-12 col-lg-6 offset-lg-3 mt-2 mt-md-0" variant="primary"  @click="gotoHome()">{{$t('mdl.conferma-t.success-btn')}}</b-button>
          </div>
        </div>
        <div class="o-registration-collapse__error" v-if="!success">
          <p><strong>{{$t('mdl.conferma-t.fail-heading')}}</strong></p><br>
          <p>{{$t('mdl.conferma-t.fail-text')}}</p>
          <p>{{errorStr}}</p>
          <div class="col-12">
            <b-button class="col-lg-6 offset-lg-3 mt-2 mt-md-0" variant="primary"  @click="gotoDispo()">{{$t('mdl.conferma-t.error-btn')}}</b-button>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>

import AppBreadcrumb from "@/components/molecules/AppBreadcrumb";
import AppTitle from "@/components/molecules/AppTitle";
import router from "@/router";
export default {
  name: "dashboard",
  components: {AppTitle, AppBreadcrumb},
  props: {},
  data() {
    return {
      visible:false
    };
  },
  computed:{
    errorStr(){
      let erro=this.$route.query.errore;
      if (erro==null)
        return this.$t("mdl.conferma-t.errors.ERR_NO_ERROR");
      return erro;
    },
    success(){
      let esito=this.$route.query.success;
      if (esito==null)
        return false;
      return (esito==="true");
    }
  },
  created() {},
  methods: {
    gotoHome(){
      router.push("/mdl");
    },
    gotoDispo(){
      router.push("/mdl/disponibilita");
    }
  }
};
</script>
